.blackButton {
  background: var(--black) !important;
  color: var(--white) !important;
}
  
.whiteButton{
  background: #FFFFFF;
  color: var(--black);
  border: 1px solid var(--light_black) !important;
}
.carteavButton {
  background: var(--carteav);
  color: var(--white);
}

.greyButton {
  background: var(--white_grey);
  color: var(--light_black);
  border: 1px solid var(--light_black) !important;
}

.dangerButton{
  background: var(--red);
  color:var(--white);
}

.textCarteavButton {
  background: none !important;
  color: var(--carteav);
  border:none !important;

}

.textBlackButton {
  background: none !important;
  color: var(--black);
  border:none !important;

}
  
.textGreyButton {
  background-color: none !important;
  color: var(--grey);
  border:none !important;

}


.generalButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  width: 100%;
  width: min(90vw, 400px);
  height: 42px ;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  gap:8px;
  border:1px solid transparent;
}

.generalButton:active {
  opacity: 0.6;
}

.generalButton.active {
  opacity: 0.6;
}

.smallButton {
  height: 30px;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  width: min(45vw, 200px);
  height: 32px ;
}

.disableTouchStyle:active, 
.disableTouchStyle.active {
  opacity: 1;
}


/* New class for disabled state */
.generalButton.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none; /* Ensures the button is not clickable */
}