:root {
  --carteav: hsl(197, 100%, 43%);
  --carteav_dark: #077eb1;
  --carteav_darker: hsl(205, 91%, 35%);
  --carteav_light: rgba(0, 167, 238, 0.9);
  --white: #faf0e6;
  --black: #070A0E;
  --light_black: #24292e;
  --red:#FF3239;
  --light_red: hsl(357, 100%, 66%);
  --warning: #ffb82d; 
  --grey:#616E87;
  --light_grey: #EBEEF7;
  --white_grey: #f7f8fb;
  --grey-text: #6A6777;
  --green:#86E896;
  --light_green:#B2F9B2;
  --dark_green: #0DD02C;
  --input_color:#EBEEF6;

  /* disbled colors */
  --disabled_light_grey: #dcd9d9;
  --disabled_darker: hsl(205, 40%, 33%);
}
* {
  padding: 0;
  margin: 0;
}
 
@supports (padding-top: env(safe-area-inset-top)) { 
  body {
    height: calc(100% + var(--safe-area-inset-top));
  }
}

@media (display-mode: fullscreen) {
  body {
    height: 100%;
  }
}

@media (display-mode: standalone) {
  body {
    height: 100%;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace !important;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.center-column{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center-row{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.custom-cart-icon{
  background: none !important;
  border: none !important;
  z-index: 999 !important;
}

.user-icon-on-drag{
  z-index: 1000 !important;
}
.rotatable-div {
  transition: transform 0.5s ease-in-out; 
}