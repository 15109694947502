.overlay {
  z-index: 15;
  position: absolute;
  width: 100vw;
  height: 100vh; 
  background-color: rgba(255, 255, 255, 0.95);
  display: flex; /* Use flexbox for easier centering */
  flex-direction: column; /* Stack items vertically */
  align-items: center;   /* Center horizontally */
  justify-content: center; /* Center vertically */
  font-style: normal;
  text-align: center;
  color: #000000;
}
.overlay-content {  /* New parent container for all content */
  width: 90%; 
  max-width: 500px; 
  margin: 30px auto;
}
  .title{
   text-align: center;
  }
  
  .rating-icons-container {
    width: 100%; 
    max-width: 500px; /* Adjust the max-width as needed for iPads */
}

  .rating-icons {
      display: flex;
      justify-content: space-around; /* Distribute icons evenly */
      flex-wrap: wrap;      /* Allow icons to wrap to the next line */
  }

  
  .rating-icon {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 48px;  
    height: 48px; 
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; /* For iOS */

}
  
  .rating-icon.selected {
    /* Style for selected icons (e.g., change color) */
  }
  .rate-icon {
    display: flex; /* Center the icon */
    align-items: center;
    justify-content: center;
    width: 48px; /* Adjust size as needed */
    height: 48px; /* Adjust size as needed */
    cursor: pointer;

  }
  
  .rating-icon svg {
    fill: currentColor;  /* Inherit color from parent */
    transition: filter 0.3s ease; /* Transition the filter property */
  }

  .rating-item:not(.active) .rate-icon svg {
    filter: grayscale(100%); /* Apply grayscale when not active */
  }

  .rating-item.active .rate-icon svg {
    fill: currentColor; /* Color when active (use your desired color) */
  }
  
  .question{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    align-items: center; 
    gap:12px;
  }
  
  .question .MuiOutlinedInput-root fieldset {
    border-color: var(--black) !important;  /* Override default and use the variable */
}

.question .MuiOutlinedInput-root:hover fieldset,
.question .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--carteav) !important;
}


.rating-item {
  display: flex;
  flex-direction: column;
  align-items: center; 
}
  .rating-label {
    margin-top: 8px; /* Add some space above the label */
  }

/* In RateRide.css */
.rating-icons-container .rating-item .rate-icon:hover,
.rating-icons-container .rating-item .rate-icon:focus {
    background-color: transparent;
}

  
  /* ... your existing styles ... */

 